import React from "react";
import {Link} from "gatsby";
import styled from "styled-components";
import Icon from "./Icon";

const Styles = styled.div`
  margin-top: 3.125rem;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, .24);
  width: 100%;
  box-sizing: border-box;
  margin-bottom: -3px;
  display: flex;
  flex-direction: column;

  @media only screen and (min-width: 1024px) {
    margin-bottom: 0;
  }
`;

const Top = styled.div`
  background: ${props => props.darkMode ? "var(--mdGrey)" : "var(--white)"};
  padding: 1.5rem 1.25rem;
  
  @media only screen and (min-width: 1024px) {
    padding: 2.125rem 0;
  }
`;

const LogoLink = styled(Link)`
  display: inline-flex;
  align-items: center;
  height: 4.263rem;

  img {
    width: 5.813rem;
    display: flex;
  }

  @media only screen and (min-width: 1024px) {
    height: 9.563rem;

    img {
      width: 7.813rem;
    }
  }
`;

const Ul = styled.ul`
  list-style: none;
  display: flex;
  flex-direction: column;
  
  li {
    margin: 0 0 .625rem;
    position: relative;
    padding-left: .5rem;

    &::before {
      content: '·';
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }

    a {
      text-decoration: none;
      color: var(--black);
      font-size: .75rem;
    }

    p {
      font-size: .75rem;
    }
  }
  
  &.dark {
    li, a {
      color: var(--white);
    }
  }

  @media only screen and (min-width: 1024px) {
    height: 9.563rem;
    justify-content: center;

    li {
      margin: 0 0 .625rem;

      &:last-child {
        margin: 0;
      }
    }
  }
`;

const Logos = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 2rem;

  a {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 4rem;
  }
  
  &.dark {
    svg {
      fill: var(--white);
    }
    
    .icon-dfc {
      stroke: var(--white);
    }
  }

  svg {
    fill: var(--mdGrey);
    margin: 0 1.25rem 0 0;
    float: right;
    transition: .2s;

    &:hover, &:focus {
      fill: var(--primary);
      transition: .2s;
    }
  }

  .icon {
    &-snaptrip {
      height: 1.563rem;
      width: 4rem;
    }

    &-lmc {
      height: 1.863rem;
      width: 6rem;
    }

    &-lr {
      width: 8rem;
      height: 1.563rem;
    }

    &-bc {
      height: 2.2rem;
      width: 6.6rem;
    }

    &-dfc {
      width: 5.4rem;
      height: 1.563rem;
      stroke: var(--mdGrey);

      &:hover, &:focus {
        stroke: var(--primary);
      }
    }

    &-vh {
      width: 3rem;
      height: 2.7rem;
    }

    &-ik {
      width: 4rem;
      height: 3.7rem;
    }
  }

  @media only screen and (min-width: 1024px) {
    margin-top: 0;
    justify-content: flex-end;
    
    a {
      &.mr-0 {
        svg {
          margin-right: 0;
        }
      }
    }
  }
`;

const Break = styled.div`
  @media only screen and (min-width: 1024px) {
    flex-basis: 100%;
    height: 0;
  }
`;

const Bottom = styled.div`
  background: ${props => props.darkMode ? "var(--white)" : "var(--mdGrey)"};
  padding: 1.5rem 1.25rem;

  @media only screen and (min-width: 1024px) {
    padding: 2.125rem 0;
  }
`;

const BottomContent = styled.div`
  text-align: right;
  
  &.dark {
    p, a {
      color: var(--mdGrey);
    }
  }
  
  p, a {
    color: var(--white);
    font-size: 0.625rem;
    margin-bottom: 0;
  }

  p:last-of-type {
    margin-top: 1rem;
  }
`;

export default function Footer(props) {
    const year = new Date().getFullYear();
    const darkMode = props.footerDarkVersion;
    const logo = props.logo?.asset?.fixed?.src;
    const whiteLogo = props.whiteLogo ? props.whiteLogo.asset.fixed.src : logo;
    const app = (process.env.GATSBY_APP_ENV || "LWHT") ;
    let title;

switch(true) {
  case app === "LWHT":
    title = "Lodges With Hot Tubs";
    break;
  case app === "LUXH":
    title = "Luxury Hotels";
    break;
  case app === "CH":
    title = "Cottage Holidays";
    break;
  case app === "CS":
    title = "Coastal Stays";
    break;
  case app === "CW":
    title = "Cottage Weekend";
    break;
  case app === "CC":
    title = "Cottages Cornwall";
    break;
  case app === "DFH":
    title = "Dog Friendly Hotels";
    break;
  case app === "FVR":
    title = "Florida Vacation Rentals";
    break;
  case app === "HL":
    title = "Hotels London";
    break;
  case app === "LH":
    title = "Lodge Holidays";
    break;
  case app === "LC":
    title = "Luxury Cottages";
    break;
  case app === "RC":
    title = "Remote Cottages";
    break;
  case app === "VH":
    title = "Villa Holidays";
    break;
  case app === "ROMC":
    title = "Romantic Cottages";
    break;
  case app === "UH":
    title = "Unique Holidays";
    break;
  case app === "UB":
    title = "UK Breaks";
    break;
  default:
    title = "Lodges With Hot Tubs";
}


    return (
        <Styles>
            <Top darkMode={darkMode}>
                <div className="container">
                    <div className="row">
                        <div className="col-1 hidden-m"/>
                        <div className="col-3">
                            <LogoLink to="/">
                                <img alt="logo" src={darkMode ? whiteLogo : logo}/>
                            </LogoLink>
                        </div>
                        <div className="col-2">
                            <Ul className={darkMode ? "dark" : ""}>
                                <li>
                                    <Link to="/terms-of-service/">
                                        <p>Terms of service</p>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/privacy-policy/">
                                        <p>Privacy policy</p>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/contact/">
                                        <p>Contact us</p>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/about-us/">
                                        <p>About us</p>
                                    </Link>
                                </li>
                                <li>
                                    <a href="https://www.snaptripgroup.com/list-your-property" target="_blank">
                                        List your property
                                    </a>
                                </li>
                                {process.env.GATSBY_APP_ENV === "LWHT" && <li>
                                    <a href="/all-destinations/" target="_blank">
                                      All destinations
                                    </a>
                                </li>}
                                <li>
                                    <a href="/blog/" target="_blank">
                                        Inspiration
                                    </a>
                                </li>
                                {process.env.GATSBY_APP_ENV === "DFH" && <li>
                                    <a href="/all-locations/">
                                      Sitemap
                                    </a>
                                </li>}
                            </Ul>
                        </div>
                        <div className="col-5">
                            <Logos className={darkMode ? "dark" : ""}>
                                <a href="https://www.snaptrip.com/" target="_blank" rel="noreferrer">
                                    <Icon name="icon-snaptrip"/>
                                </a>
                                <a href="https://www.laterooms.com/" target="_blank" rel="noreferrer">
                                    <Icon name="icon-lr"/>
                                </a>
                                <a href="https://www.dogfriendlycottages.co.uk/" target="_blank" rel="noreferrer" className="mr-0">
                                    <Icon name="icon-dfc"/>
                                </a>
                                <Break/>
                                <a href="https://big-cottages.com/" target="_blank" rel="noreferrer">
                                    <Icon name="icon-bc"/>
                                </a>
                                <a href="https://www.lastminute-cottages.co.uk/" target="_blank" rel="noreferrer">
                                    <Icon name="icon-lmc"/>
                                </a>
                                <a href="https://www.villaholidays.com/" target="_blank" rel="noreferrer" className="mr-0">
                                    <Icon name="icon-vh"/>
                                </a>
                            </Logos>
                        </div>
                    </div>
                </div>
            </Top>
            <Bottom darkMode={darkMode}>
                <div className="container">
                    <div className="row">
                        <div className="col-1 hidden-m"/>
                        <div className="col-3"/>
                        <div className="col-2"/>
                        <div className="col-5">
                            <BottomContent className={darkMode ? "dark" : ""}>
                                <p>
                                    &copy;
                                    {" "}
                                    {title}
                                    {" "}
                                    {year}
                                </p>
                                <p>"{title}" is a trading name of Snaptrip Group Limited</p>
                                <p>Registered in England and Wales.</p>
                                <p>Company Registration Number 08774859.</p>
                                <p>VAT Registration Number 201864825.</p>
                                <p>
                                    <b>
                                        Part of the
                                        {" "}
                                        <a href="https://www.snaptripgroup.com/" target="_blank" rel="noreferrer">
                                            Snaptrip Group
                                        </a>
                                    </b>
                                </p>
                            </BottomContent>
                        </div>
                    </div>
                </div>
            </Bottom>
        </Styles>
    );
}